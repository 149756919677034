import React, { useState, useEffect } from "react";
import classes from "./AddEditStaff.module.css";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import { Col, Row } from "react-bootstrap";
import { ProfileWithEditButton } from "../../Component/ProfileWithEditButton";
import { Input } from "../../Component/Input";
import { Button } from "../../Component/Button/Button";
import CustomPhoneInput from "../../Component/CustomPhoneInput";
import { DropDown } from "../../Component/DropDown/DropDown";
import CountryStateCity from "../../Component/CountryStateCity";
import { Checkbox } from "../../Component/Checkbox/Checkbox";
import { userAvatar } from "../../constant/imagePath";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BaseURL,
  apiHeader,
  formRegEx,
  formRegExReplacer,
  validateEmail,
} from "../../config/apiUrl";
import { Patch, Post } from "../../Axios/AxiosFunctions";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import validator from "validator";
import { ImagesPost } from "../../Axios/ImagePost";
const genderOption = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
];
const AddEditStaff = () => {
  const { access_token } = useSelector((state) => state.authReducer);
  const navigate = useNavigate();
  const data = useLocation()?.state;
  const [avatar, setAvatar] = useState(userAvatar);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [permission, setPermission] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (data) {
      setFullName(data ? data?.fullName : "");
      setEmail(data ? data?.email : "");
      setPhone(data ? data?.contact : "");
      setAddress(data ? data?.address : "");
      setGender(data ? { label: data?.gender, value: data?.gender } : null);
      setCountry(data ? data?.country : "");
      setPhone(data ? data?.contact : "");
      setState(data ? data?.state : "");
      setCity(data ? data?.city : "");
      setZipcode(data ? data?.zipCode : "");
      setPermission(
        data
          ? data?.permission == "edit"
            ? "Allow permission to edit"
            : ""
          : ""
      );
      setAvatar(data ? data?.photo : userAvatar);
    }
  }, []);
  console.log(data);
  const submitHandler = async () => {
    const apiUrl = BaseURL(
      data ? `users/updateStaff?staffId=${data?.id}` : "users/createStaff"
    );

    const params = {
      avatar,
      fullName,
      contact: phone,

      email,
      gender: gender?.value,
      country: typeof country === "string" ? country : country?.name,
      state: typeof state === "string" ? state : state?.name,
      city: typeof city === "string" ? city : city?.name,
      address,
      permission: permission === "Allow permission to edit" ? "edit" : "view",
      zipCode: zipcode,

      role: "staff",
    };

    for (let key in params) {
      if (!params[key]) {
        return toast.error(
          `Please fill the ${key
            .replace(formRegEx, formRegExReplacer)
            .toLowerCase()} field!`
        );
      }
    }
    if (!validateEmail(email)) {
      return toast.warn("Please enter valid email");
    }
    if (!validator.isMobilePhone(phone)) {
      return toast.warn("Please enter valid phone number");
    }

    delete params?.avatar;
    let imageKey;
    setIsLoading(true);
    if (typeof avatar !== "string") {
      imageKey = await ImagesPost(BaseURL("upload"), [avatar]);
    }

    if (typeof avatar !== "string" && imageKey === undefined) {
      return;
    }
    let body = {
      ...params,
      photo:
        typeof avatar === "string" ? avatar : imageKey?.data?.data?.image[0],
    };
    const response = data
      ? await Patch(apiUrl, body, apiHeader(access_token))
      : await Post(apiUrl, body, apiHeader(access_token));
    if (response !== undefined) {
      toast.success(`Staff ${data ? "updated" : "added"} successfully`);
      navigate(-1);
    }
    setIsLoading(false);
  };
  return (
    <>
      <SideBarSkeleton
        header={
          <h1
            style={{
              fontSize: "26px",
              color: "white",
              margin: "0",
              fontFamily: "var(--ff-secondary-bold)",
            }}
          >
            {data ? "Edit" : "Create"} Staff
          </h1>
        }
      >
        <div className={classes.pageMain}>
          <Row>
            <Col md={6}>
              <Row className={classes.rowMain}>
                <Col md={12}>
                  <div className={classes.profileImage}>
                    <h6>Profile Picture</h6>
                    <ProfileWithEditButton
                      setUpdateImage={setAvatar}
                      updateImage={avatar}
                      isEdit={true}
                    />
                    <h6>Basic Information</h6>
                  </div>
                </Col>
                <Col md={12}>
                  <Input
                    setter={setFullName}
                    value={fullName}
                    label={"Full Name"}
                    placeholder={"Full Name"}
                  />
                </Col>
                <Col md={12}>
                  <CustomPhoneInput
                    label={"Contact No"}
                    setter={setPhone}
                    value={phone}
                  />
                </Col>
                <Col md={12}>
                  <Input
                    setter={setEmail}
                    value={email}
                    disabled={data && true}
                    label={"Email"}
                    placeholder={"Email"}
                    type={"email"}
                  />
                </Col>
                <Col md={12}>
                  <DropDown
                    options={genderOption}
                    labelClassName={classes.label}
                    setter={setGender}
                    value={gender}
                    label={"Gender"}
                    placeholder={"Gender"}
                    customStyle={{
                      borderRadius: "10px",
                      border: "1px solid var(--border-color)",
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row className={classes.rowMain}>
                <Col md={12}>
                  <h6 className={classes.addressHead}>Address Detail</h6>
                </Col>

                <CountryStateCity
                  setSelectedCountry={setCountry}
                  selectedCountry={country}
                  setSelectedCity={setCity}
                  selectedCity={city}
                  setSelectedState={setState}
                  selectedState={state}
                  stateLabel="State"
                />
                <Col md={12}>
                  <Input
                    setter={setAddress}
                    value={address}
                    label={"Address"}
                    placeholder={"Address"}
                  />
                </Col>
                {/* <Col xl={12} className={classes.inputField}>
                  <Maps
                    setCoordinates={setCoordinates}
                    setAddress={setAddress}
                    address={address}
                    setPlaceDetail={setPlaceDetail}
                    type="place"
                    label={"address"}
                    placeClass={classes.placeClass}
                  />
                
                </Col> */}

                <Col md={12}>
                  <Input
                    type={"text"}
                    setter={setZipcode}
                    value={zipcode}
                    label={"Zip/Postal Code"}
                    placeholder={"Zip/Postal Code"}
                  />
                </Col>
                <Col md={12}>
                  <Checkbox
                    label={"Allow permission to edit"}
                    setValue={setPermission}
                    value={permission}
                  />
                </Col>
              </Row>
            </Col>
            <Col md={12}>
              <div className={classes.btnMain}>
                <Button
                  disabled={isLoading}
                  onClick={submitHandler}
                  label={isLoading ? "Please wait..." : "Submit"}
                />
                <Button onClick={() => navigate(-1)} label={"Discard"} />
              </div>
            </Col>
          </Row>
        </div>
      </SideBarSkeleton>
    </>
  );
};

export default AddEditStaff;
