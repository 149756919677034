export const handlePermission = (user) => {
  if (user?.role == "admin") {
    return true;
  }
  if (user?.role == "staff" && user?.permission == "edit") {
    return true;
  }
  return false;
};

export const handleYearOptions = () => {
  const options = [{ label: "Current", value: "current" }];
  var startingYear = 1,
    maxYear = 5;
  var thisYear = new Date().getFullYear();

  for (var i = startingYear; i <= maxYear; i++) {
    var year = thisYear - i;
    let op = { label: year, value: year };
    options.push(op);
  }
  return options;
};
