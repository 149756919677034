import React, { useState } from "react";
import classes from "./SideBar.module.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { signOutRequest } from "../../store/auth/authSlice";
import { RxDashboard } from "react-icons/rx";
import { CgToolbarBottom } from "react-icons/cg";
import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri";
import { PiMoneyFill, PiNoteFill, PiWallet } from "react-icons/pi";
import { Logo } from "../../constant/imagePath";
import { Button } from "../Button/Button";
import { BsChatSquare } from "react-icons/bs";
import { LuHome, LuStretchVertical } from "react-icons/lu";
import { MdEngineering, MdPrivacyTip } from "react-icons/md";
import { GrUserSettings } from "react-icons/gr";
import { IoPeopleOutline } from "react-icons/io5";
import { VscSettingsGear } from "react-icons/vsc";
import { CiLogout } from "react-icons/ci";
import { FaBarsProgress, FaListCheck, FaUser } from "react-icons/fa6";
import { BiSupport } from "react-icons/bi";
import { MdOutlineContactPhone } from "react-icons/md";
import { MdOutlineNewspaper } from "react-icons/md";
import { PiChatTextBold } from "react-icons/pi";
import { handlePermission } from "../../Helper/Permission";
const RenderItem = ({ icon, title, subMenu = [], path }) => {
  const active = useLocation()?.pathname;
  const [subnav, setSubnav] = useState(false);
  const subActive = subMenu.find((item, index) => item?.path == active);
  const showSubnav = () => setSubnav(!subnav);
  return (
    <>
      <Link
        className={[
          classes?.listItemContainer,
          path == active && classes?.active,
          subActive && classes?.subActive,
          subnav && classes?.marginZero,
        ].join(" ")}
        to={subMenu?.length > 0 ? "#" : path}
        onClick={() => {
          if (subMenu?.length > 0) {
            showSubnav(!subnav);
          }
        }}
      >
        {icon}
        <span>{title}</span>
        {subMenu?.length > 0 &&
          (subnav ? (
            <RiArrowUpSFill
              size={20}
              color={"var(--white-color)"}
              className={classes?.dropDownIcon}
            />
          ) : (
            <RiArrowDownSFill
              size={20}
              color={"var(--white-color)"}
              className={classes?.dropDownIcon}
            />
          ))}
      </Link>
      {subnav &&
        subMenu.map((item, index) => {
          return (
            <Link
              className={[
                classes?.innerItemContainer,
                item?.path == active && classes?.active,
              ].join(" ")}
              key={index}
              to={item?.path}
            >
              {item?.icon}
              <span>{item?.label}</span>
            </Link>
          );
        })}
    </>
  );
};

const SideBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state?.authReducer?.user);
  const permission = handlePermission(user);
  const HandleSubmitSignOut = () => {
    dispatch(signOutRequest());
    navigate("/login");
  };

  return (
    <div className={classes?.mainContainer}>
      <div className={classes?.logoContainer}>
        <img src={Logo} onClick={() => navigate("/")} />
      </div>
      <div className={classes.itemsContainer}>
        <RenderItem
          icon={<FaBarsProgress size={20} color={"var(--main-color)"} />}
          title={"Dashboard"}
          path={"/"}
        />
        <RenderItem
          icon={<GrUserSettings size={20} color={"var(--main-color)"} />}
          title={"User Management"}
          path={"/user-management"}
        />
        <RenderItem
          icon={<FaListCheck size={20} color={"var(--main-color)"} />}
          title={"Service Management"}
          path={"/service-management"}
        />
        <RenderItem
          icon={<PiMoneyFill size={20} color={"var(--main-color)"} />}
          title={"Withdrawals"}
          path={"/withdrawals"}
        />
        <RenderItem
          icon={<MdEngineering size={20} color={"var(--main-color)"} />}
          title={"Service Providers"}
          path={"/service-providers"}
        />
        <RenderItem
          icon={<PiWallet size={20} color={"var(--main-color)"} />}
          title={"E-Wallet"}
          path={"/wallet"}
        />

        <RenderItem
          icon={<LuStretchVertical size={20} color={"var(--main-color)"} />}
          title={"Reports"}
          path={"/reports"}
        />
        <RenderItem
          icon={<BsChatSquare size={20} color={"var(--main-color)"} />}
          title={"Chats"}
          path={"/chats"}
        />

        {permission && (
          <RenderItem
            icon={<RxDashboard size={20} color={"var(--main-color)"} />}
            title={"CMS"}
            path={"#"}
            subMenu={[
              {
                path: "/cms/home",
                icon: <LuHome size={20} color={"var( --white-color)"} />,
                label: "Home",
              },
              {
                path: "/cms/about",
                icon: <FaUser size={20} color={"var( --white-color)"} />,
                label: "About",
              },
              {
                path: "/cms/faq",
                icon: (
                  <PiChatTextBold size={20} color={"var( --white-color)"} />
                ),
                label: "FAQs",
              },
              {
                path: "/cms/privacy-policy",
                icon: <MdPrivacyTip size={20} color={"var( --white-color)"} />,
                label: "Privacy Policy",
              },
              {
                path: "/cms/terms-and-condition",
                icon: <PiNoteFill size={20} color={"var( --white-color)"} />,
                label: "Terms And Condition",
              },
              {
                path: "/cms/footer",
                icon: (
                  <CgToolbarBottom size={20} color={"var( --white-color)"} />
                ),
                label: "Footer",
              },
              {
                path: "/cms/contact",
                icon: (
                  <IoPeopleOutline size={20} color={"var( --white-color)"} />
                ),
                label: "Contact",
              },
            ]}
          />
        )}

        <RenderItem
          icon={<VscSettingsGear size={20} color={"var(--main-color)"} />}
          title={"Settings"}
          path={"/settings"}
        />
        <RenderItem
          icon={<MdOutlineContactPhone size={20} color={"var(--main-color)"} />}
          title={"Contact us"}
          path={"/contact"}
        />
        <RenderItem
          icon={<MdOutlineNewspaper size={20} color={"var(--main-color)"} />}
          title={"NewsLetter"}
          path={"/newsletter"}
        />
        <RenderItem
          icon={<BiSupport size={20} color={"var(--main-color)"} />}
          title={"Support"}
          path={"/support"}
        />

        <Button className={classes["logout-btn"]} onClick={HandleSubmitSignOut}>
          <CiLogout size={25} color="#fff" /> <span>Logout</span>
        </Button>
      </div>
    </div>
  );
};

export default SideBar;
