import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { handlePermission } from "./Permission";

function ProtectedRouter({ element: Element }) {
  const isAuthenticated = useSelector((state) => state?.authReducer?.isLogin);
  const user = useSelector((state) => state?.authReducer?.user);
  const permission = handlePermission(user);
  const active = useLocation()?.pathname;
  const permissionRoutes = [
    "/add-edit-staff",
    "/add-edit-service-management",
    "/cms/terms-and-condition",
    "/cms/privacy-policy",
    "/cms/footer",
    "/cms/about",
    "/cms/faq",
    "/cms/home",
  ];
  const decisionRoute = permissionRoutes?.includes(active);

  return (
    <>
      {!isAuthenticated ? (
        <Navigate replace to="/login" />
      ) : !decisionRoute ? (
        Element
      ) : permission ? (
        Element
      ) : (
        <Navigate replace to="/" />
      )}
    </>
  );
}
export default ProtectedRouter;
