import React, { useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { AiFillBell } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Style from "./AfterLoginHeader.module.css";

import { useDispatch, useSelector } from "react-redux";
import PoperComponent from "../PopperComponent";
import { signOutRequest } from "../../store/auth/authSlice";
import { userAvatar } from "../../constant/imagePath";
export const AfterLoginHeader = ({ className, header, drawerBtn }) => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state?.authReducer);
  const [openPopper, setOpenPopper] = useState(false);
  const dispatch = useDispatch();
  const anchorRef = useRef(null);
  const { newNotifications } = useSelector((state) => state?.commonReducer);
  const handleToggle = () => {
    setOpenPopper((prevOpen) => !prevOpen);
  };
  const handleActionClick = (flag) => {
    if (flag === "Settings") {
      navigate("/settings");
    }
    if (flag === "Logout") {
      dispatch(signOutRequest());
      navigate("/login");
    }
  };

  return (
    <Container className={`${[Style.navbarContainer, className].join(" ")}`}>
      {drawerBtn && drawerBtn}
      {header && <div className={Style.actionWrapper}>{header}</div>}

      <div className={Style["profile-container"]}>
        <div
          className={Style.notifyIconDiv}
          onClick={() => navigate("/notifications")}
        >
          <AiFillBell
            size={30}
            className={Style.notifyIcon}
            color="var(--white-color)"
          />
          {newNotifications?.length > 0 && (
            <div className={Style.countDiv}>
              <span>{newNotifications?.length}</span>
            </div>
          )}
        </div>
        <p className={Style["profile-name"]}>{user?.fullName}</p>
        <div
          ref={anchorRef}
          onClick={() => {
            setTimeout(() => {
              handleToggle();
            }, 100);
          }}
          className={`${[Style.profileImg]} ${Style["profile-wrapper"]}`}
        >
          <img src={user?.photo || userAvatar} alt="..." layout="fill" />
        </div>
        <div className={Style.popperMainDiv}>
          <PoperComponent
            handleClick={handleActionClick}
            open={openPopper}
            setOpen={setOpenPopper}
            anchorRef={anchorRef}
            placement="bottom-end"
            data={["Settings", "Logout"]}
          />
        </div>
      </div>
    </Container>
  );
};
