import "./assets/Styles/style.css";
import "./assets/Styles/table.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.min.css";
import "react-modern-drawer/dist/index.css";
import "font-awesome/css/font-awesome.min.css";
import "react-quill/dist/quill.snow.css";
import React, { useState, Suspense, lazy, useEffect, useRef } from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Loader } from "./Component/Loader";
import ScrollToTop from "./Helper/ScrollToTop";
import AddEditStaff from "./pages/AddEditStaff";
import ProtectedRouter from "./Helper/ProtectedRoute";
import BeforeLoginRoute from "./Helper/BeforeLoginRoute";
import { Get } from "./Axios/AxiosFunctions";
import { updateUser } from "./store/auth/authSlice";
import { saveNewNotification } from "./store/common/commonSlice";
import { BaseURL, apiUrl } from "./config/apiUrl";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
const Withdrawals = lazy(() => import("./pages/Withdrawals/index"));
const WithdrawalProfile = lazy(() =>
  import("./pages/Withdrawals/WithdrawalProfile")
);
const ForgetPassword = lazy(() => import("./pages/Auth/ForgetPassword"));
const Login = lazy(() => import("./pages/Auth/login"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Settings = lazy(() => import("./pages/Settings"));
const NotFound = lazy(() => import("./pages/NotFound"));
const ServiceProviders = lazy(() => import("./pages/ServiceProviders"));
const ServiceManagement = lazy(() => import("./pages/ServiceManagement"));
const UserManagement = lazy(() => import("./pages/UserManagement"));
const ViewAdmins = lazy(() => import("./pages/ViewAdmins"));
const ViewStaff = lazy(() => import("./pages/ViewStaff"));
const ViewServiceProvider = lazy(() => import("./pages/ViewServiceProvider"));
const RecentActivity = lazy(() => import("./pages/RecentActivity"));
const ServiceProviderDetail = lazy(() =>
  import("./pages/ServiceProviders/ViewServiceProvider")
);
// Edit Both Service Provider and Customer
const EditUser = lazy(() => import("./pages/EditUser"));
const AddServiceManagement = lazy(() =>
  import("./pages/ServiceManagement/AddEditService")
);
const ViewServiceManagement = lazy(() =>
  import("./pages/ServiceManagement/ViewService")
);
const Support = lazy(() => import("./pages/Support"));
const ViewCutomers = lazy(() => import("./pages/ViewCutomers"));
const EWallet = lazy(() => import("./pages/EWallet"));
const Chat = lazy(() => import("./pages/Chat"));
const Reports = lazy(() => import("./pages/Reports"));
const Notification = lazy(() => import("./pages/Notification"));
const Contact = lazy(() => import("./pages/Contact"));
const TermsAndCondition = lazy(() => import("./pages/Cms/TermsAndCondition"));
const PrivacyPolicy = lazy(() => import("./pages/Cms/PrivacyPolicy"));
const Footer = lazy(() => import("./pages/Cms/Footer"));
const CmsHome = lazy(() => import("./pages/Cms/Home"));
const CmsContact = lazy(() => import("./pages/Cms/Contact"));
const About = lazy(() => import("./pages/Cms/About"));
const Faq = lazy(() => import("./pages/Cms/Faq"));
const NewsLetter = lazy(() => import("./pages/Newsletter"));

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const { access_token } = useSelector((state) => state?.authReducer);
  const { isLogin } = useSelector((state) => state?.authReducer);
  const { newNotifications } = useSelector((state) => state?.commonReducer);
  const { user } = useSelector((state) => state?.authReducer);
  const socket = useRef(null);
  const dispatch = useDispatch();

  // notification
  const getNotificationFromSocket = async () => {
    const responseData = await Get(
      BaseURL("notifications"),
      access_token,
      false,
      dispatch
    );
    if (responseData !== undefined) {
      const unreadNotifications =
        responseData?.data?.data?.notifications?.filter(
          (item) => item?.seen == false
        );
      dispatch(saveNewNotification(unreadNotifications));
    }
    socket.current = io(apiUrl, { transports: ["websocket"] });
    socket.current.emit("join", { id: user?.id });
    socket.current.on("new-notification", ({ notification }) => {
      dispatch(saveNewNotification([...newNotifications, notification]));
    });
  };

  //getUsers
  const getMe = async () => {
    setIsLoading(true);
    const response = await Get(
      BaseURL("users/getMe"),
      access_token,
      false,
      dispatch
    );
    if (response) {
      dispatch(updateUser(response.data.data));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (isLogin) {
      getNotificationFromSocket();
      getMe();
    }
  }, [isLogin]);

  if (isLoading) {
    return <Loader className="vh-100" />;
  }

  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <ScrollToTop />
        <Suspense fallback={<Loader className={"vh-100"} />}>
          <Routes>
            <Route
              path="/login"
              exact
              element={<BeforeLoginRoute element={<Login />} />}
            />
            <Route
              path="/forget-password"
              exact
              element={<BeforeLoginRoute element={<ForgetPassword />} />}
            />

            {/* ServiceManagement */}
            <Route
              path="/service-management"
              exact
              element={<ProtectedRouter element={<ServiceManagement />} />}
            />
            <Route
              path="/add-edit-service-management"
              exact
              element={<ProtectedRouter element={<AddServiceManagement />} />}
            />
            <Route
              path="/view-service-management"
              exact
              element={<ProtectedRouter element={<ViewServiceManagement />} />}
            />

            <Route
              path="/user-management"
              exact
              element={<ProtectedRouter element={<UserManagement />} />}
            />
            <Route
              path="/admins/:id"
              exact
              element={<ProtectedRouter element={<ViewAdmins />} />}
            />
            <Route
              path="/staff/:id"
              exact
              element={<ProtectedRouter element={<ViewStaff />} />}
            />

            <Route
              path="/service-provider/:id"
              exact
              element={<ProtectedRouter element={<ViewServiceProvider />} />}
            />
            {/*  Edit Both Service Provider and Customer */}
            <Route
              path="/edit-user"
              exact
              element={<ProtectedRouter element={<EditUser />} />}
            />
            <Route
              path="/withdrawals"
              exact
              element={<ProtectedRouter element={<Withdrawals />} />}
            />
            <Route
              path="/withdrawals/:id"
              exact
              element={<ProtectedRouter element={<WithdrawalProfile />} />}
            />

            <Route
              path="/newsletter"
              exact
              element={<ProtectedRouter element={<NewsLetter />} />}
            />

            {/* ServiceManagement */}

            <Route
              path="/settings"
              exact
              element={<ProtectedRouter element={<Settings />} />}
            />
            <Route
              path="/add-edit-staff"
              exact
              element={<ProtectedRouter element={<AddEditStaff />} />}
            />
            <Route
              path="/service-providers"
              exact
              element={<ProtectedRouter element={<ServiceProviders />} />}
            />
            <Route
              path="/view-service-provider/:id"
              exact
              element={<ProtectedRouter element={<ServiceProviderDetail />} />}
            />

            <Route
              path="/"
              exact
              element={<ProtectedRouter element={<Dashboard />} />}
            />

            <Route
              path="/reports"
              exact
              element={<ProtectedRouter element={<Reports />} />}
            />
            <Route
              path="/support"
              exact
              element={<ProtectedRouter element={<Support />} />}
            />
            <Route
              path="/customer/:id"
              exact
              element={<ProtectedRouter element={<ViewCutomers />} />}
            />
            <Route
              path="/wallet"
              exact
              element={<ProtectedRouter element={<EWallet />} />}
            />
            <Route
              path="/chats"
              exact
              element={<ProtectedRouter element={<Chat />} />}
            />
            <Route
              path="/notifications"
              exact
              element={<ProtectedRouter element={<Notification />} />}
            />
            <Route
              path="/recent-activity"
              exact
              element={<ProtectedRouter element={<RecentActivity />} />}
            />
            <Route
              path="/contact"
              exact
              element={<ProtectedRouter element={<Contact />} />}
            />
            {/* cms--------- */}

            <Route
              path="/cms/home"
              exact
              element={<ProtectedRouter element={<CmsHome />} />}
            />

            <Route
              path="/cms/terms-and-condition"
              exact
              element={<ProtectedRouter element={<TermsAndCondition />} />}
            />
            <Route
              path="/cms/privacy-policy"
              exact
              element={<ProtectedRouter element={<PrivacyPolicy />} />}
            />
            <Route
              path="/cms/footer"
              exact
              element={<ProtectedRouter element={<Footer />} />}
            />
            <Route
              path="/cms/about"
              exact
              element={<ProtectedRouter element={<About />} />}
            />
            <Route
              path="/cms/contact"
              exact
              element={<ProtectedRouter element={<CmsContact />} />}
            />
            <Route
              path="/cms/faq"
              exact
              element={<ProtectedRouter element={<Faq />} />}
            />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
