import React from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import classes from "./PopperComponent.module.css";

const PoperComponent = ({
  setOpen,
  open,
  anchorRef,
  scrollRef,
  data,
  handleClick,
  isCloseOnClick = true,
  placement = "bottom",
}) => {
  const [refScroll, setRefScroll] = React.useState(0);
  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  React.useEffect(() => {
    window.addEventListener("resize", () => {
      setOpen(false);
    });
    if (scrollRef?.current !== null) {
      scrollRef?.current?.addEventListener("scroll", () => {
        setRefScroll(scrollRef?.current?.scrollTop);
        if (scrollRef?.current?.scrollTop !== refScroll) {
          setOpen(false);
        }
        if (scrollRef?.current?.scrollBottom !== refScroll) {
          setOpen(false);
        }
      });
    }
    return () => {
      scrollRef?.current?.removeEventListener("scroll", () => {});
      window?.removeEventListener("resize", () => {});
    };
  }, [scrollRef?.current]);

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      placement={placement}
      transition
      disablePortal
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper className={[classes.popperDiv]}>
            <ClickAwayListener onClickAway={() => setOpen(false)}>
              <MenuList
                autoFocusItem={open}
                id="composition-menu"
                aria-labelledby="composition-button"
                onKeyDown={handleListKeyDown}
              >
                {data?.map((item, i) => {
                  return (
                    <MenuItem
                      onClick={() => {
                        handleClick(item);
                        isCloseOnClick && setOpen(false);
                      }}
                      key={i}
                    >
                      {item}
                    </MenuItem>
                  );
                })}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export default PoperComponent;
